import React from 'react'
import { Link } from 'gatsby'
import Heading from 'components/Heading'
import AuthorCard from 'components/AuthorCard'

import { blogCard, link } from './blogCard.module.css'

function BlogCard( {title, authorName, authorImageURL, authorAltText, datePublished, to}) {
    return(
        <Link to={to}  className={link}>
            <div className={blogCard}>           
                <Heading level='h3'>{title}</Heading>

                <AuthorCard
                    authorName={authorName}
                    altText={authorAltText}
                    authorImageURL={authorImageURL}
                    datePublished={datePublished}
                />
            </div>
        </Link>
        
    )
}

export default BlogCard