import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import Heading from 'components/Heading'
import AuthorCard from 'components/AuthorCard'

import { mainBlogCard, rightContainer, link, mainImage } from './mainBlogCard.module.css'

function MainBlogCard( {image, altText, title, authorName, authorImageURL, authorAltText, datePublished, to}) {
    return (
        <Link to={to} className={link}>
            <div className={mainBlogCard}>
                <div>
                    <GatsbyImage className={mainImage} image={image} alt={altText} />
                </div>
                
                <div>
                    <div className={rightContainer}>
                        <Heading level='h3'>{title}</Heading>

                        <AuthorCard
                            authorName={authorName}
                            altText={authorAltText}
                            authorImageURL={authorImageURL}
                            datePublished={datePublished}
                        />
                    </div>
                </div>
            </div>
        </Link>
        
    )
}

export default MainBlogCard