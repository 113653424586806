import React from 'react'
import Heading from 'components/Heading'

import { authorContent, authorDetails } from './authorCard.module.css'

function AuthorCard({authorName, authorImageURL, altText, datePublished, highlightedTitle=false}) {
    let colorStyle = highlightedTitle ? {color : `var(--red)`} : null
    
    return ( 
        <div className={authorContent}>
            <img src={authorImageURL} alt={altText}/>
            <div className={authorDetails}>
                <Heading level='h4' style={colorStyle}>{authorName}</Heading>
                <p>{datePublished}</p>
            </div>
        </div>
    )
}

export default AuthorCard;