import Layout from "components/layout";
import PageTitle from "components/PageTitle";
import Seo from "components/seo";
import { graphql, Link } from "gatsby";
import React from "react";
import BlogCard from "components/BlogCard";
import MainBlogCard from "components/MainBlogCard";
import { getImage } from "gatsby-plugin-image"

import { blogContent, cardsContainer, paginationNav, linkArrow, linkNumber } from "./archiveBlog.module.css"

function ArchiveBlog({ data, pageContext }) {

    const { seo } = data.page
    const allPost = data.allWpPost

    const lastItemIndex =  (pageContext.skip + pageContext.limit < allPost.edges.length) ? pageContext.skip + pageContext.limit : allPost.edges.length
    let cards = allPost.edges.slice(pageContext.skip,  lastItemIndex)

    const { node: mainCardNode } = cards.shift();
    
    const mainCard = 
        <MainBlogCard 
            image={getImage(mainCardNode.featuredImage.node.localFile)}
            altText={mainCardNode.featuredImage.node.altText}
            title={mainCardNode.title}
            authorName={mainCardNode.author.node.name}
            authorImageURL={mainCardNode.author.node.avatar.url}
            authorAltText={mainCardNode.author.node.name}
            datePublished={mainCardNode.date}
            to={'/blogs/' + mainCardNode.slug}
        />

    let cardsJSX = cards.map( ({node}) =>  (
        <BlogCard
            title={node.title}
            authorName={node.author.node.name}
            authorAltText={node.author.node.name}
            authorImageURL={node.author.node.avatar.url}
            datePublished={node.date}
            to={'/blogs/' + node.slug}
        />
    ));

    const pageNavigation = thisPage => {
        var links = [];
        const active = {
            borderRadius: "4px",
            backgroundColor: "var(--blue)",
            color: "var(--white)"
        }

        if (pageContext.prev !== null) {
            links.push(
                <Link
                    to={`/blogs/${
                        pageContext.prev === 0 ? "" : pageContext.prev
                    }`}
                    key={0}
                    className={linkArrow}
                >
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.16802 12.5L7.5 11.09L3.17341 6.5L7.5 1.91L6.16802 0.5L0.500001 6.5L6.16802 12.5Z" fill="#2F3542"/>
                    </svg>

                </Link>
            );
        }

        // divider between arrow and numbers
        links.push(
            <svg width="2" height="35" viewBox="0 0 2 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="2.18557e-08" x2="0.999998" y2="35" stroke="#747D8C"/>
            </svg>
        )

        for (let i = 1; i < pageContext.numPages + 1; i++) {
            links.push(
                <Link
                    to={`/blogs/${i === 1 ? "" : i}`}
                    key={i}
                    style={thisPage === i ? active : null}
                    className={linkNumber}
                >
                    {i}
                </Link>
            );
        }

        // 2nd divider
        links.push(
            <svg width="2" height="35" viewBox="0 0 2 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="2.18557e-08" x2="0.999998" y2="35" stroke="#747D8C"/>
            </svg>
        )

        if (pageContext.next !== null) {
            links.push(
                <Link
                    to={`/blogs/${
                        pageContext.next === 0 ? "" : pageContext.next
                    }`}
                    key={pageContext.numPages + 1}
                    className={linkArrow}
                >
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.83198 12.5L0.5 11.09L4.82659 6.5L0.499999 1.91L1.83198 0.5L7.5 6.5L1.83198 12.5Z" fill="#2F3542"/>
                    </svg>
                </Link>
            );
        }

        return <nav className={paginationNav}>{links}</nav>;
    }
    
    return (
        <Layout>
            <Seo
                title={seo.title}
                description={seo.metaDesc}
            />
            <PageTitle title="Joe (Blogs)"/>

            <section className={blogContent}>
                {mainCard}

                <div className={cardsContainer}>
                    {cardsJSX}
                </div>

                {pageNavigation(pageContext.currentPage)}
            </section>
        </Layout>
    );
}

export const query = graphql`
    query AllPostQuery {
        page: wpPage(title: { eq: "(Joe) Blogs" }) {
            seo {
                metaDesc
                metaKeywords
                title
            }
        }
        allWpPost {
            edges {
                node {
                    title
                    slug
                    date(formatString: "DD/MM/YY")
                    author {
                        node {
                            name
                            avatar {
                                url
                            }
                        }
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: BLURRED
                                        layout: FULL_WIDTH
                                    )
                                }
                                publicURL
                            }
                            altText
                        }
                    }
                }
            }
        }
    }
`;

export default ArchiveBlog;
